

















































// Core
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import Debounce from '@/decorators/Debounce'

// Types
import { UsersActions, UsersMutations } from '@store/users/types'

// Interfaces
import { Role } from '@store/common/Interface'
import { User, UsersState } from '@store/users/interfaces'
import { Paginate, RequestParams } from '@store/interfaces'
import { ElForm } from 'element-ui/types/form'

// Modules
const NSUsers = namespace('usersModule')

@Component({
  name: 'DialogSelectUser',

  components: {
    'v-user-list': () => import('./UserList.vue'),
  },
})
export default class DialogSelectUserComponent extends Vue {
  @PropSync('visible', Boolean) private _visible!: boolean

  @PropSync('roleId', Number) private _roleId!: number

  @Prop(Function)
  private handleConnectProjectToUser!: (userId: number) => Promise<void>

  @NSUsers.State((state: UsersState) => state.roles)
  private roles!: Role[]

  @NSUsers.Mutation(UsersMutations.M_CLEAR_USERS)
  private clearUsers!: () => void

  @NSUsers.Action(UsersActions.A_GET_USERS)
  private fetchUsers!: (params?: RequestParams) => Promise<Paginate<User>>

  private loading:boolean = false
  private search: string = ''

  private form: { roleId: number | null } = {
    roleId: null,
  }

  protected rules = {
    roleId: [{ required: true, message: 'Обязательное поле', trigger: 'blur' }],
  }

  async created() {
    this.loading = true

    this.clearUsers()
    await this.fetchUsers()

    this.loading = false
  }

  @Debounce(1000)
  private async handleChangeSearch() {
    if (this.search.length >= 3 || this.search.length === 0) {
      this.loading = true

      this.clearUsers()
      await this.fetchUsers({ search: this.search })

      this.loading = false
    }
  }

  private _handleConnectProjectToUser(userId: number) {
    const form = this.$refs.form as ElForm

    form.validate(isValid => {
      if (isValid) {
        this.loading = true

        this._roleId = this.form.roleId as number
        this.handleConnectProjectToUser(userId)
          .finally(() => (this.loading = false))
        form.resetFields()
      }
    })
  }

  private handleClose() {
    const form = this.$refs.form as ElForm

    form.resetFields()

    this._visible = false
  }
}
